import dynamic from "next/dynamic";

const Home = dynamic(() => import("../js/Components/HomeComponent/Home"), {
    ssr: true,
});


//Dynamically load the home component and return it
const index = ({ data }) => {
    return (
        <>
{/*         
            <Head>
                <title>{global_settings.site_name}</title>
                <meta
                    name="description"
                    content={global_settings.site_description}
                />
                <meta name="keywords" content="Furniture Hire London" />
            </Head> */}

            <Home homeData={data}/>
        </>
    );
};

export async function getServerSideProps({ query, res, req }) {

    try {
        
        const request = await fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}get-home-content`);
        let data = await request.json();
    
        if(data.exception) {
            return {notFound: true}
        }
    
        res.setHeader(
            'Cache-Control',
            'public, s-maxage=43200, stale-while-revalidate=59'
        )
    
        // Pass data to the page via props
        return {
            props: { data },
        };
    } catch (error) {
        return {
            props: {
                error: true,
                statusCode: 500

            }
        }
        
    }
}

export default index;
